import styled from '@emotion/styled';
import { type FC } from 'react';
import { theme } from '../../../theme';

const Message = styled.p`
  color: ${theme.colors.textError};
`;

interface ErrorMessageProps {
  text: string;
  description?: string;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ text, description }) => (
  <div style={{ display: 'block' }}>
    <Message>{text}</Message>
    {description && <Message>{description}</Message>}
  </div>
);
