import { debounce } from 'lodash';
import { useState } from 'react';
import { useEventListener } from './useEventListener';

export const useHoverDebounced = <T extends HTMLElement | undefined>(
  elementRef: T,
  ms: number,
): boolean => {
  const [value, setValue] = useState<boolean>(false);

  const handleMouseEnter = debounce(() => setValue(true), ms);

  const handleMouseLeave = () => {
    setValue(false);
    handleMouseEnter.cancel();
  };

  useEventListener('mouseenter', handleMouseEnter, elementRef);
  useEventListener('mouseleave', handleMouseLeave, elementRef);

  return value;
};
