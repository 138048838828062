import type { AreaSystems, AreaUnits } from 'convert-units/definitions/area';
import type { LengthSystems, LengthUnits } from 'convert-units/definitions/length';
import type { MassSystems, MassUnits } from 'convert-units/definitions/mass';
import type { SpeedSystems, SpeedUnits } from 'convert-units/definitions/speed';
import type { VolumeSystems, VolumeUnits } from 'convert-units/definitions/volume';
import { createContext, useContext, useState, type FC, type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../../components/common/error/ErrorMessage';
import { CenteredSpinner } from '../../components/common/loading/CenteredSpinner';
import { useQuerySiteConfigurationsBySiteId } from '../../gql-types/generated-types-super-graph';
import { convertMeasurements, excludes } from '../../helpers/convert/convert';
import type { ConvertUnits, Units } from '../../helpers/convert/types';
import type { SiteConfigContextType, SiteConfigProviderProps } from './types';

const defaultUnits = {
  speed: {
    system: convertMeasurements().describe('km/h').system,
    base: 'km/h',
    unit: 'km/h',
  },
  mass: {
    system: convertMeasurements().describe('kg').system,
    base: 'kg',
    unit: 'kg',
  },
  area: {
    system: convertMeasurements().describe('m2').system,
    base: 'm2',
    unit: 'm2',
  },
  length: {
    system: convertMeasurements().describe('m').system,
    base: 'm',
    unit: 'm',
  },
  volume: {
    system: convertMeasurements().describe('m3').system,
    base: 'm3',
    unit: 'm3',
  },
  volumeFluid: {
    system: convertMeasurements().describe('l').system,
    base: 'l',
    unit: 'l',
  },
} as Units;

const SiteConfigContext = createContext({} as SiteConfigContextType);

export const SiteConfigProvider: FC<PropsWithChildren<SiteConfigProviderProps>> = ({
  siteId,
  children,
}) => {
  const { t } = useTranslation();

  const [units, setUnits] = useState<Units>(defaultUnits);

  const siteconfigConfig = useQuerySiteConfigurationsBySiteId({
    variables: { siteId },
    pollInterval: Number(import.meta.env.VITE_POLL_INTERVAL),
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'standby',
    returnPartialData: true,
    // onCompleted won't be called unless this is set...
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const siteconfigConfigData = data.sites.site?.configuration;

      setUnits({
        speed: {
          system: convertMeasurements().describe(
            (siteconfigConfigData?.speedUnit.id as SpeedUnits) ?? 'km/h',
          ).system as SpeedSystems,
          base: 'km/h',
          unit: (siteconfigConfigData?.speedUnit.id as SpeedUnits) ?? 'km/h',
        },
        mass: {
          system: convertMeasurements().describe(
            (siteconfigConfigData?.weightUnit.id as MassUnits) ?? 'kg',
          ).system as MassSystems,
          base: 'kg',
          unit: (siteconfigConfigData?.weightUnit.id as MassUnits) ?? 'kg',
        },
        area: {
          system: convertMeasurements().describe(
            (siteconfigConfigData?.distanceUnit.id as AreaUnits) ?? 'm2',
          ).system as AreaSystems,
          base: 'm2',
          unit: (siteconfigConfigData?.volumeUnit.id as AreaUnits) ?? 'm2',
        },
        length: {
          system: convertMeasurements().describe(
            (siteconfigConfigData?.distanceUnit.id as LengthUnits) ?? 'm',
          ).system as LengthSystems,
          base: 'm',
          unit: (siteconfigConfigData?.distanceUnit.id as LengthUnits) ?? 'm',
        },
        volume: {
          system: convertMeasurements().describe(
            (siteconfigConfigData?.volumeUnit.id as VolumeUnits) ?? 'm3',
          ).system as VolumeSystems,
          base: 'm3',
          unit: (siteconfigConfigData?.volumeUnit.id as VolumeUnits) ?? 'm3',
        },
        volumeFluid: {
          system: convertMeasurements().describe(
            (siteconfigConfigData?.fluidVolumeUnit.id as VolumeUnits) ?? 'l',
          ).system as VolumeSystems,
          base: 'l',
          unit: (siteconfigConfigData?.fluidVolumeUnit.id as VolumeUnits) ?? 'l',
        },
      });
    },
  });

  if (!siteconfigConfig.data && siteconfigConfig.loading) {
    return <CenteredSpinner style={{ height: '100%' }} />;
  }

  if (siteconfigConfig.error) {
    return <ErrorMessage text={t('error')} description={siteconfigConfig.error?.message} />;
  }

  const convertToBestUnit: SiteConfigContextType['convertToBestUnit'] = (value, type) => {
    const { system, base } = units[type];
    const convertionResult = convertMeasurements(value)
      .from(base)
      .toBest({ system, exclude: excludes[type] });
    if (!convertionResult) return null;
    convertionResult.unit = convertionResult.unit.replace(/2$/, '²').replace(/3$/, '³');

    let rounded = Math.round(convertionResult.val);
    let roundedForDisplay = rounded.toLocaleString('en-US');

    if (type !== 'speed') {
      if (Math.abs(rounded) < 10) {
        rounded = Math.round((convertionResult.val + Number.EPSILON) * 100) / 100;
        roundedForDisplay = rounded.toLocaleString('en-US', {
          minimumFractionDigits: 2,
        });
      } else if (Math.abs(rounded) < 100) {
        rounded = Math.round((convertionResult.val + Number.EPSILON) * 10) / 10;
        roundedForDisplay = rounded.toLocaleString('en-US', {
          minimumFractionDigits: 1,
        });
      }
    }

    return {
      ...convertionResult,
      unit: convertionResult.unit as ConvertUnits,
      rounded,
      roundedForDisplay: `${roundedForDisplay} ${convertionResult.unit}`,
    };
  };

  const convertToSiteConfigUnit: SiteConfigContextType['convertToSiteConfigUnit'] = (
    value,
    type,
  ) => {
    const { unit, base } = units[type];
    const result = convertMeasurements(value).from(base).to(unit);
    return { val: result, rounded: Math.round(result) };
  };

  const convertToSiteUnit: SiteConfigContextType['convertToSiteUnit'] = (value, type) => {
    const { unit, base } = units[type];
    const result = convertMeasurements(value).from(base).to(unit);
    return { val: result, rounded: Math.round(result) };
  };

  const convertToUnit: SiteConfigContextType['convertToUnit'] = (value, type, unit) => {
    const result = convertMeasurements(value).from(units[type].unit).to(unit);
    return { val: result, rounded: Math.round(result) };
  };

  return (
    <SiteConfigContext.Provider
      value={{
        convertToBestUnit,
        convertToSiteUnit,
        convertToSiteConfigUnit,
        convertToUnit,
        units,
      }}
    >
      {children}
    </SiteConfigContext.Provider>
  );
};

export const useSiteConfigContext = () => useContext(SiteConfigContext);
