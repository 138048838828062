import { type SystemIconId } from '@volvo/vce-uikit';
import { theme } from '../theme';
import type { MachineType } from './types';

const primary = '#f7d302';
const primaryHover = '#F3DD62';
const other = '#FFA000';
const otherHover = '#FFB54D';
const inactive = theme.colors.borderSubtle;
const inactiveHover = theme.colors.border;
export const inactiveOutline = theme.colors.borderSelected;
const selectedColor = theme.colors.textOnButtonPrimary;

export type MachineAppearance = {
  icon: SystemIconId;
  color: string;
  outlineColor?: string;
  strokeWidth?: number;
};

const unknownVehicleIcon: SystemIconId = 'question-mark';

export const getMachineAppearance = (
  machineType?: MachineType | string,
  online: boolean = true,
  hovering: boolean = false,
  selected: boolean = false,
): MachineAppearance => {
  const getStyle = (type: 'primary' | 'other'): Omit<MachineAppearance, 'icon'> => {
    if (selected) {
      return { color: selectedColor, strokeWidth: 2 };
    }
    if (online) {
      if (hovering) {
        return type === 'primary' ? { color: primaryHover } : { color: otherHover };
      }
      return type === 'primary' ? { color: primary } : { color: other };
    }
    if (hovering) {
      return { color: inactiveHover, outlineColor: inactiveOutline };
    }
    return { color: inactive, outlineColor: inactiveOutline };
  };

  switch (machineType) {
    case 'wheel-loader':
      return {
        icon: 'wheel-loader',
        ...getStyle('primary'),
      };
    case 'hauler':
      return {
        icon: 'rigid-haulers',
        ...getStyle('primary'),
      };
    case 'truck':
      return {
        icon: 'truck-heavy',
        ...getStyle('primary'),
      };
    case 'excavator':
      return {
        icon: 'excavator',
        ...getStyle('primary'),
      };
    case 'compactor':
      return {
        icon: 'compactor',
        ...getStyle('primary'),
      };
    case 'dozer':
      return {
        icon: unknownVehicleIcon,
        ...getStyle('primary'),
      };
    case 'grader':
      return {
        icon: unknownVehicleIcon,
        ...getStyle('primary'),
      };
    case 'scraper':
      return {
        icon: unknownVehicleIcon,
        ...getStyle('primary'),
      };
    case 'paver':
      return {
        icon: 'paver',
        ...getStyle('primary'),
      };
    case 'demolition':
      return {
        icon: unknownVehicleIcon,
        ...getStyle('primary'),
      };
    case 'pipelayer':
      return {
        icon: unknownVehicleIcon,
        ...getStyle('primary'),
      };
    case 'articulated-hauler':
      return {
        icon: 'articulated-haulers',
        ...getStyle('primary'),
      };
    case 'car':
      return {
        icon: 'car-profile',
        ...getStyle('other'),
      };
    case 'pedestrian':
      return {
        icon: 'user',
        ...getStyle('other'),
      };
    default:
      return {
        icon: unknownVehicleIcon,
        ...getStyle('other'),
      };
  }
};
