import configureMeasurements from 'convert-units';
import area, { type AreaUnits } from 'convert-units/definitions/area';
import length, { type LengthUnits } from 'convert-units/definitions/length';
import mass, { type MassUnits } from 'convert-units/definitions/mass';
import speed, { type SpeedUnits } from 'convert-units/definitions/speed';
import volume, { type VolumeUnits } from 'convert-units/definitions/volume';

import type { ConvertMeasures, ConvertSystems, ConvertUnits } from './types';

export const convertMeasurements = configureMeasurements<
  ConvertMeasures,
  ConvertSystems,
  ConvertUnits
>({
  volume,
  mass,
  length,
  speed,
  area,
});

type Excludes = {
  speed: SpeedUnits[];
  mass: MassUnits[];
  area: AreaUnits[];
  length: LengthUnits[];
  volume: VolumeUnits[];
  volumeFluid: VolumeUnits[];
};

export const excludes: Excludes = {
  speed: ['m/s', 'knot'],
  mass: [],
  area: ['ha'],
  length: ['fathom', 'nMi'],
  volume: ['kl', 'ml', 'l', 'Ml', 'Gl'],
  volumeFluid: [
    'kl',
    'Ml',
    'Gl',
    'm3',
    'cm3',
    'mm3',
    'km3',
    'krm',
    'tsk',
    'msk',
    'kkp',
    'glas',
    'kanna',
  ],
};
