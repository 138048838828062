import type { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyState as EmptyStateVCE } from '@volvo/vce-uikit';

type Props = { style?: CSSProperties };

export const EmptyState: FC<Props> = ({ style }) => {
  const { t } = useTranslation();
  return (
    <section style={{ margin: '40px 40px 72px', ...style }}>
      <EmptyStateVCE
        headingText={t('no-data')}
        bodyText={t('no-data-body')}
        icon="weather-unknown"
      />
    </section>
  );
};
