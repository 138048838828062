import { useEffect, useRef } from 'react';

export const useEventListener = <
  KH extends keyof HTMLElementEventMap,
  T extends HTMLElement | undefined,
>(
  eventName: KH,
  handler: (event: HTMLElementEventMap[KH] | Event) => void,
  targetElement: T,
  options?: boolean | AddEventListenerOptions,
) => {
  const savedHandler = useRef(handler);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    if (!targetElement || !(targetElement && targetElement.addEventListener)) return;

    const listener: typeof handler = (event) => {
      savedHandler.current(event);
    };

    targetElement.addEventListener(eventName, listener, options);

    return () => {
      targetElement.removeEventListener(eventName, listener, options);
    };
  }, [eventName, options, targetElement]);
};
